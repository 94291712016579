.flex-container {
    display: flex;
}

body,
html {
    margin: 0;
    padding: 0;
    /* font-size: 62.5%; */
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}


.home {
    background-color: whitesmoke;
}
header {
    height: 10%;
    background-color: #333;
    color: whitesmoke;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 200%;
    display: flex;
    justify-content: left;
    padding-left: 5%;
    align-items: center;
}



@media only screen and (max-width: 1550px) {
    .header_brand {
        width: 30%;
        margin-left: 7rem;
        padding: 1rem
    }
}

@media only screen and (max-width: 1351px) {
    .header_brand {
        width: 50%;
        margin-left: 0.1rem;
    }
}

@media only screen and (min-width: 1551px) {
    .header_brand {
        margin-top: 30px;
        margin-bottom: 10px;
        width: 25%;
        margin-left: 7rem;
        padding: 1rem
    }


}
.header_brand_offset {
    padding-bottom: 58px;
}

.carousel_text_colour {
    text-align: left;
    font-weight: 600;
    color: whitesmoke;
}

.carousel_text_white {
    text-align: left;
    font-weight: 600;
    color: whitesmoke;
}

.carousel_subtext_bg_white {
    font-weight: 400;
    text-align: left;
    font-style: italic;
    color: black;
}

.carousel_subtext_bg_white {
    font-weight: 400;
    text-align: left;
    font-style: italic;
    color: black;
}

@media only screen and (min-width: 1150px) {
    .carousel_subtext_bg_colour {
        font-weight: 400;
        font-style: italic;
        text-align: left;
        color: whitesmoke;
        display: block;
    }
}

@media only screen and (max-width: 1151px) {
    .carousel_subtext_bg_colour {
        font-weight: 400;
        font-style: italic;
        text-align: left;
        color: whitesmoke;
        display: none;
    }
    .carousel_text_white {
        text-align: left;
        font-size: 23px;
        font-weight: 600;
        color: whitesmoke;
    }
}

.carousel_subtext_bg_colour_link {
    font-weight: 400;
    font-style: italic;
    text-align: left;
    color: whitesmoke;
    display: inherit;
}

@media only screen and (max-width: 1690px) {
    .carouselcaption_left {
        white-space: normal;
        max-width: 20%;
        position: absolute;
        right: 15%;
        bottom: 25%;
        left: 15%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
    .carouselcaption_right {
        white-space: normal;
        max-width: 24%;
        position: absolute;
        right: 5%;
        bottom: 20%;
        left: 55%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
}

@media only screen and (max-width: 1500px) {
    .carouselcaption_left {
        white-space: normal;
        max-width: 20%;
        position: absolute;
        right: 15%;
        bottom: 10%;
        left: 15%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
}

@media only screen and (min-width: 1691px) {
    .carouselcaption_left {
        white-space: normal;
        max-width: 20%;
        position: absolute;
        right: 15%;
        bottom: 30%;
        left: 15%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
    .carouselcaption_right {
        white-space: normal;
        max-width: 20%;
        position: absolute;
        right: 5%;
        bottom: 35%;
        left: 55%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
}
@media only screen and (max-width: 699px) {
    .carouselcaption_left {
        white-space: normal;
        max-width: 20%;
        position: absolute;
        right: 15%;
        bottom: 20%;
        left: 15%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
    .carousel_text_white{
        font-size: 20px;
    }
    .carousel_subtext_bg_colour_link{
        font-size: 12px;
    }
    .carouselcaption_right {
        white-space: normal;
        max-width: 20%;
        position: absolute;
        right: 5%;
        bottom: 30%;
        left: 55%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
}

@media only screen and (max-width: 376px) {
    .carouselcaption_left {
        white-space: normal;
        max-width: 20%;
        position: absolute;
        right: 15%;
        bottom: 8%;
        left: 15%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
    .carousel_text_white{
        font-size: 15px;
    }
    .carousel_subtext_bg_colour_link{
        font-size: 10px;
    }
    .carouselcaption_right {
        white-space: normal;
        max-width: 20%;
        position: absolute;
        right: 5%;
        bottom: 15%;
        left: 55%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: left;
    }
}
@media only screen and (max-width: 1691px) {
    #carousel-normal{
        display: none;
    }
    #carousel-mobile{
        display: block;

}
}
@media only screen and (min-width: 830px) {
    #carousel-normal{
        display: block;
    }
    #carousel-mobile{
        display: none;

}
}

.light-dark {
    background-color: #323335!important;
    position: relative;
    opacity: 0.95
}

.sticky {
    background-color: #323335!important;
    position: fixed!important;
    z-index: 100;
    top: 0;
    width: 100%;
}


/*-------------------------------------------------------*/

.navbar-white{
    text-align: center;
    color: #d8d8d8 !important;
}
.navbar-white:hover{
    color: white !important;
}
.navbar-white:visited{
    color: #d8d8d8 !important;
}
.navbar-white:visited:hover{
    color: white !important;
}
@media only screen and (min-width: 1060px) {
    .navbaritems {
        margin: auto;
        max-width: 250px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: center;
        color: rgba(255,255,255,255);
    }
    .navlogo {
        height: 42px;
        width: auto;
        margin: 0rem 2rem;
    }
    .nav_collapse {
        width : 60% !important;
        display: flex!important;
        flex-basis: auto;
        flex-grow: 0.5;
        align-items: center;
    }
}

@media only screen and (max-width: 1061px) {
    .navlogo {
        height: 42px;
        width: auto;
        margin: 0rem 1rem;
    }
    .nav-collapse {
        width : 100%;
        display: flex!important;
        flex-basis: auto;
        flex-grow: 0.5;
        align-items: center;
    }
}



@media only screen and (max-width: 767px) {
    .nav-collapse {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
}

.nav_link_logo {
    padding: unset;
}

.nav_link_logo_container {
    margin-left: auto;
}

@media only screen and (max-width: 767px) {
    .nav_link_logo_container {
        margin-right: auto;
        margin-right: auto;
        margin-left: auto;
    }
}
@media only screen and (min-width: 1500px) {
    .navbar-collapse {
        width: 50% 
    }
}

@media only screen and (max-width: 1499px) {
    .navbar-collapse {
        width: 80% 
    }
}


@media only screen and (max-width: 1000px) {
    .navbar-collapse {
        width: 100%
    }
}

.navlogo:hover {
    cursor: pointer;
}

.navbar_items_container {
    width: 100% !important;
    margin-right: auto;
    margin-left: auto;
}
@media only screen and (max-width: 960px) {
.navbaritems {
    margin: auto;
    max-width: 250px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    color: rgba(255,255,255,255);
}
}
@media only screen and (min-width: 961px) {
    .navbaritems {
        margin: auto;
        max-width: 250px;
        padding-left: 1%;
        padding-right: 1%;
        display: flex;
        justify-content: center
    }
    .navlogo{
        display: none;
    }
    }

/*-------------------------------------------------------*/
@media only screen and (min-width: 831px) {
    #expo_n{
        display: block;
    }
    #expo_tablet{
        display: none;
    }
    #expo_mobile{
        display: none;
    }
    
}
@media only screen and (max-width: 830px) and (min-width: 501px) {
    #expo_n{
        display: none;
    }
    #expo_mobile{
        display: none;
    }
    #expo_tablet{
        display: block;
    }
    .expo_header_tablet {
        color: white;
        font-size: 20px;
    }
    
    .expo_subtext_tablet {
        color: white;
        font-size: 0.9rem;
        margin-bottom: 0;

    }
    
}
@media only screen and (max-width: 500px) {
    #expo_n{
        display: none;
    }
    #expo_mobile{
        display: block;
    }
    #expo_tablet{
        display: none;
    }
    .expo_header_mobile {
        color: white;
        font-size: 15px;
    }
    
    .expo_subtext_mobile {
        color: white;
        font-size: 0.6rem;
        font-size: 0.6rem;
        margin-bottom: 0;

    }
    
}

.expo_container {
    position: relative;
    text-align: center;
    color: white;
}

.expo_image {
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 1280px) {
    .expo_text_container {
        text-align: left;
        position: absolute;
        top: 25%;
        left: 10%;
        color: white;
    }

    .expo_header {
        color: white;
        font-size: 180%;
    }
    
    .expo_subtext {
        color: white;
        font-size: 90%;
    }
    }
    

    @media only screen and (min-width: 1281px) {
.expo_text_container {
    position: absolute;
    top: 25%;
    left: 15%;
    color: white;
}

.expo_header {
    color: white;
    font-size: calc(2rem + 100%);
    text-align: left
}

.expo_text {
    color: white;
    font-size: calc(1rem + 100%);
    text-align: left
}

.expo_subtext {
    color: white;
    font-size: 120%;
}
    }
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/*-----------------------------------------------------------------------------------------------*/

#footer {
    height: 20px;
    background: #222;
    color: white;
}

@media only screen and (max-width: 455px) {
    .footer_grid {
        color: white;
        text-align: center;
        padding-top: 80px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom:0.5%;
        width: 70%;
        height: auto;
        background-color: inherit;
        display: grid;
        grid-template-columns: 100%
    }
    .footer_label {
        font-size: 1rem;
        text-align: center;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .footer_links {
        font-size: 0.8rem;
        padding-bottom: 0px;
        text-align: center;
    }
}

@media only screen and (min-width: 456px) {
    .footer_grid {
        color: white;
        text-align: center;
        padding-top: 80px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 0.8%;
        width: 80%;
        height: auto;
        background-color: inherit;
        display: grid;
        grid-template-columns: 50% 50%
    }
    .footer_label {
        font-size: 1.1rem;
        text-align: center;
        padding-bottom: 10px
    }
    .footer_links {
        font-size: 0.9rem;
        padding-bottom: 0px;
        text-align: center;
    }
}

@media only screen and (min-width: 960px) {
    .footer_grid {
        color: white;
        text-align: center;
        padding-top: 80px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 4%;
        width: 50%;
        height: auto;
        background-color: inherit;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%
    }
    .footer_label {
        text-align: left;
        padding-bottom: 10px
    }
    .footer_links {
        text-align: left;
        padding-bottom: 10px;
    }
}



/* --------------------------------------------------------------*/

/* Style the tab */

  
  /* Change background color of buttons on hover */
/* Style the tab */
@media only screen and (min-width: 1011px) {
.productline{
    padding-top:1.5%;
    clear: both;
}
}
@media only screen and (max-width: 1010px) {
    .productline{
        clear: both;
    }
}
@media only screen and (max-width: 567px) {
    .productline{
        padding-top:1.5%;
        clear: both;
    }
}
@media only screen and (max-width: 630px) {
    .tab {
        display: none;
        overflow: hidden;
        background-color: whitesmoke;
        border: none;
        width: 80%;
        height: 70px;
        margin: auto;
        font-size: 18px;
      }
    }
    @media only screen and (max-width: 630px) {
        .form-control{
            margin-top: 15px !important;
            margin-left: auto !important;
            margin-right: auto !important;
            text-align: center;
            width: 80% !important;
            display: block;
        }
    }
    @media only screen and (min-width: 631px) {
        .form-control{
            display: none !important;
        }
    }

@media only screen and (min-width: 1445px) {
.tab {
    overflow: hidden;
    background-color: whitesmoke;
    border: none;
    width: 80%;
    height: 70px;
    margin: auto;
    font-size: 18px;
  }

  .tab_accessories_software {
    overflow: hidden;
    background-color: whitesmoke;
    border: none;
    width: 80%;
    height: 70px;
    margin: auto;
    font-size: 18px;
  }
}

@media only screen and (max-width: 1446px) {
  .tab {
    overflow: hidden;
    background-color: whitesmoke;
    border: none;
    width: 95%;
    height: 85px;
    margin: auto;
    font-size: 15px;
  }

  .tab_accessories_software {
    overflow: hidden;
    background-color: whitesmoke;
    border: none;
    width: 95%;
    height: 85px;
    margin: auto;
    font-size: 15px;
  }
}

@media only screen and (max-width: 630px) {
    .tab {
      overflow: hidden;
      background-color: whitesmoke;
      border: none;
      width: 95%;
      height: 85px;
      margin: auto;
      font-size: 15px;
    }
  
    .tab_accessories_software {
    display: none !important;
      overflow: hidden;
      background-color: whitesmoke;
      border: none;
      width: 95%;
      height: 85px;
      margin: auto;
      font-size: 15px;
    }
  }
  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    box-sizing: border-box;
    width: 16.666666666666666666666666666667%;
    height:100%;
    /* font-size: 15px; */
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 10px;
    transition: 0.3s;
    outline: none;
    border-bottom: 3px solid transparent !important;
  }
  .tab_accessories_software button {
    background-color: inherit;
    box-sizing: border-box;
    width: 50%;
    height:100%;
    /* font-size: 15px; */
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 10px;
    transition: 0.3s;
    outline: none;
    border-bottom: 3px solid transparent !important;
  }
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color:  whitesmoke;
    border-bottom: 3px solid grey !important;
    transition: none;
    outline: none;
  }
  .tab_accessories_software button:hover {
    background-color:  whitesmoke;
    border-bottom: 3px solid grey !important;
    transition: none;
    outline: none;
  }
  /* Create an active/current tablink class */
  .tab button.active {
    background-color:  whitesmoke;
    /* font-size: 15px; */
    border-bottom: 3px solid maroon !important;
    outline: none;
    font-weight: 500;
  }
  .tab_accessories_software button.active {
    background-color:  whitesmoke;
    /* font-size: 15px; */
    border-bottom: 3px solid maroon !important;
    outline: none;
    font-weight: 500;
  }
  .producttabcontentholder{
    padding-top: 2%;
    width: 80%;
    margin: auto;
  }
  /* Style the tab content */
  .tabcontent {
    display: none;
    text-align: center;
    padding: 6px 12px;
    border: none;
    background-color: whitesmoke;
    padding-bottom: 10%;
  }




@media only screen and (max-width: 1100px)and (min-width: 761px) {

    .product_banner_image {
        position: relative;
        width: 100%;
        filter: brightness(0.40);
    }
    #product_banner_n{
        display: none;
    }
    #product_banner_mobile{
        display: block;
    }
    .product_banner_container {
        position: relative;
        text-align: left;
        color: white;
    }
    .product_banner_text_container {
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
        color: white;
    }

    .product_banner_header {
        text-align: center;
        display: inline-block;
        width: 75%;
        color: white;
        font-size: 200%;
    }
    
    .product_banner_subtext {
        color: white;
        font-size: 100%;
    }
    .product_banner_header_mobile {
        text-align: center;
        display: inline-block;
        width: 100%;
        color: white;
        font-size: 300%;
    }
    }
    

    @media only screen and (max-width: 760px) and (min-width: 400px){
        .product_banner_image {
            position: relative;
            width: 100%;
            filter: brightness(0.40);
        }
        #product_banner_n{
            display: none;
        }
        #product_banner_mobile{
            display: block;
        }
        .product_banner_container {
            position: relative;
            text-align: left;
            color: white;
        }
        .product_banner_text_container {
            position: absolute;
            top: 50%;
            left: 40%;
            transform: translate(-50%, -50%);
            color: white;
        }
    
        .product_banner_header {
            text-align: center;
            display: inline-block;
            width: 75%;
            color: white;
            font-size: 200%;
        }
        
        .product_banner_subtext {
            color: white;
            font-size: 100%;
        }
        .product_banner_header_mobile {
            text-align: center;
            display: inline-block;
            width: 100%;
            color: white;
            font-size: 250%;
        }
    }

    @media only screen and (max-width: 399px){
        .product_banner_image {
            position: relative;
            width: 100%;
            filter: brightness(0.40);
        }
        #product_banner_n{
            display: none;
        }
        #product_banner_mobile{
            display: block;
        }
        .product_banner_container {
            position: relative;
            text-align: left;
            color: white;
        }
        .product_banner_text_container {
            position: absolute;
            top: 50%;
            left: 40%;
            transform: translate(-50%, -50%);
            color: white;
        }
    
        .product_banner_header_mobile {
            text-align: center;
            display: inline-block;
            width: 100%;
            color: white;
            font-size: 200%;
        }
        
        .product_banner_subtext {
            color: white;
            font-size: 100%;
        }
    }
    @media only screen and (max-width: 2000px)and (min-width: 1101px) {
        .product_banner_image {
            position: relative;
            width: auto;
            height: 523px;
            filter: brightness(0.40);
        }
        #product_banner_n{
            display: block;
        }
        #product_banner_mobile{
            display: none;
        }

        .product_banner_container {
            overflow: hidden;
            position: relative;
            text-align: left;
            color: white;
        }
.product_banner_text_container {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    color: white;
}

.product_banner_header {
    text-align: center;
    display: inline-block;
    width: 100%;
    color: white;
    font-size: 360%;
}

.product_banner_subtext {
    color: white;
    font-size: 120%;
}
    }

    @media only screen and (min-width: 2001px) {
        .product_banner_image {
            position: relative;
            width: 100%;
            filter: brightness(0.40);
        }
        #product_banner_n{
            display: block;
        }
        #product_banner_mobile{
            display: none;
        }
        .product_banner_container {
            overflow: hidden;
            position: relative;
            text-align: left;
            color: white;
        }
.product_banner_text_container {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    color: white;
}

.product_banner_header {
    text-align: center;
    width: 100%;
    display: inline-block;
    color: white;
    font-size: 360%;
}

.product_banner_subtext {
    color: white;
    font-size: 120%;
}
    }










#product-series{
    padding-top: 4%;
    padding-bottom: 3%;
    margin: auto;
}

#product-series-text{
    font-weight: 500;
}
#smartscope_series_description{
    width: 70%;
    margin: auto;
    padding-top: 3%;
    font-size: 18;
    text-align: justify;
}

@media only screen and (min-width: 1101px) {
.grid-test {
    display: grid;
    grid-gap: 5px;
    padding: 10px;
    grid-template-columns: 20% 80%
  }
}
  @media only screen and (max-width: 1100px) {
.grid-test {
    display: grid;
    grid-gap: 5px;
    padding: 10px;
    grid-template-columns: auto
  }
  }
  .grid-description-test{
    font-size: 15px;
    text-align: center;
    font-weight: 500;
  }
  .grid-item-img{
      width: 100%;
    font-size: 15px;
    text-align: justify;
    font-weight: 500;
  }
  .grid-item-des{
    font-size: 16px;
    text-align: justify;
    font-weight: 500;
  }

  .grid-img-test{
      margin: auto;
      object-fit: cover;
      max-height: 243.52px ;
      max-width: 100%;;
      display:flex;
      flex-direction:column;
      justify-content:center;
      text-align:center;
  }
  @media only screen and (min-width: 1101px) {
  .tabcontent-product {
    width: 80%;
    margin: auto;
    display: none;
    padding: 6px 12px;
    border: none;
    background-color: whitesmoke;
    padding-bottom: 10%;
  }
  .tech-spec-word{
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
  margin: auto;
  font-size: 25px;
  padding: 10px;
  white-space: pre;
  text-align: left;

}
}
  @media only screen and (max-width: 1100px) {
  .tabcontent-product {
    width: 95%;
    margin: auto;
    display: none;
    padding: 6px 12px;
    border: none;
    background-color: whitesmoke;
    padding-bottom: 10%;
  }
  .tech-spec-word{
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%;
  margin: auto;
  font-size: 20px;
  padding: 10px;
  white-space: pre;
  text-align: left;

}
}

  .tech-spec{
      width:100%;
      margin: auto;
      table-layout: fixed !important;
  }



  .prod-name{
      font-weight: 500;
      margin-bottom: 20px;
      width: 100%;
    margin: auto;
    font-size: 30px;
    padding: 10px;
  }
  .table-head{
      width:30%;
  }
  .table-body{
      width:70% !important;
  }

  
  tr:nth-child(2n) { 
      background: #e0e0e0;
     }

  td:first-child{ 
      font-weight: 700;
     }
  td{ 
      font-weight: 500;
      padding:10px;
     }



p{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important
}

.table-subtext{
    margin: auto;
    padding-top: 3%;
    text-align: left;
    text-decoration: underline;
    font-weight: 500;
}

.text-link{
    color:black;
    font-size: 15px;
}
.text-link:hover{
  color:#007bff !important;
  text-decoration: underline;
}
.text-link:visited{
  color:black
}
.text-link:visited:hover{
  color:black;
  text-decoration: underline;
}



/*~~~~~~~~~~~~~~contact us~~~~~~~~~~~~~~~~*/


@media only screen and (min-width: 1201px) {
    .contactus{

        width: 80%;
        margin: auto;
        padding-top: 5%;
        padding-bottom: 8%;
    
    }
    .map{
        padding: 3%;
        display: inline-block;
        vertical-align: top;
        width: 60%;
    }
    
    .contact-details{
        display: inline-block;
        vertical-align: top;
        width: 40%;
        padding: 3%;
    }
    
    .opening-hours{
        padding-top: 5%;
    }
    
    .contact-info{
        font-size: 120%;
        padding-top: 0.7vw;
    }
    .contact-info-d{
        word-break: keep-all;
        display: inline-block;
        padding-bottom: 10%;
        width: 100%;
        font-size: 120%;

    }

}
@media only screen and (max-width: 1200px) {
    .contactus{
        width: 80%;
        margin: auto;
        padding-top: 5%;
        padding-bottom: 8%;
    
    }
    .map{
        padding-top: 2%;
        display: block;
        text-align: center;
        width: 100%;
    }
    
    .contact-details{
        display: block;
        text-align: center;
        width: 100%;
    }
    
    .opening-hours{
        padding-top: 5%;
    }
    
    .contact-info{
        font-size: 120%;
        padding-top: 0.7vw;
    }
    .contact-info-d{
        word-break: break-all;
        display: block;
        text-align: center;
        padding-bottom:8%;
        width: 100%;
        font-size: 120%;

    }
}
















.solution-body{
    display: flex;
    flex-direction: column;
}
.solution-content{
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin: auto;
    display: table;
}
#learn-more:hover{
    padding-top: 1%;
    font-style: italic;
    font-weight: 500;
    color: #007bff;
}
#learn-more:visited{
    padding-top: 1%;
    font-style: italic;
    font-weight: 500;
    color: black;
}
#learn-more{
    padding-top: 1%;
    font-style: italic;
    font-weight: 500;
    color: black;
}


@media only screen and (max-width: 760px) {
    #solution-n{
        display: none;
        }
        #solution-m{
            display: block;
        }
.solution-img-text{
    padding-top: 5%;
    text-align: center;
    font-size: 130%;
}

.solution-img{
    
    display: table;
    margin: auto;
    width: 40%;
    padding: 3%;
}
.solution-container{
    width:100%;
    margin: auto;
    padding-bottom: 5%;
}
.solution-description{
    font-size: 70%;
    padding-bottom: 3%;
}
}

@media only screen and (min-width: 761px) {
    #solution-n{
        display: block;
        }
        #solution-m{
            display: none;
        }
    .solution-img{
        display: inline-block;
        margin: auto;
        width: 20%;
    }
    .solution-img-text{
        width: 80%;
        margin: auto;
        padding-left: 2% !important;
        padding: 1%;
        display: inline-block;
        vertical-align: top;
        text-align: justify;
        font-size: 150%;
    }
    .solution-container{
        width:100%;
        margin: auto;
        padding-bottom: 5%;
    }
    .solution-description{
        font-size: 70%;
    }
    }


@media only screen and (min-width: 1281px) {
    #solution-n{
        display: block;
        }
        #solution-m{
            display: none;
        }
    .solution-img{
        display: inline-block;
        margin: auto;
        width: 20%;
    }
    .solution-img-text{
        width: 80%;
        padding-left: 2% !important;
        padding: 1%;
        display: inline-block;
        vertical-align: top;
        text-align: justify;
        font-size: 150%;
    }
    .solution-container{
        width:100%;
        margin: auto;
        padding-bottom: 5%;
    }
    .solution-description{
        font-size: 70%;
    }
}

@media only screen and (min-width: 1900px) {
    #solution-n{
        display: block;
        }
        #solution-m{
            display: none;
        }
    .solution-img{
        display: inline-block;
        margin: auto;
        width: 20%;
    }
    .solution-img-text{
        width: 80%;
        padding-left: 2% !important;
        padding: 1%;
        display: inline-block;
        vertical-align: top;
        text-align: justify;
        font-size: 150%;
    }
    .solution-container{
        width:75%;
        margin: auto;
        padding-bottom: 5%;
    }
    .solution-description{
        font-size: 70%;
    }
}

#solution-details{
    width: 80%;
    padding-top: 3%;
    display: block;
    margin: auto;
}



/* SOFTWARE ACCESSORY PAGE*/

.software_items {
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
    padding: 0;

  }
  
.software_items li {
    display: inline-block;
    font-size: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 0 auto;

  }


  @media only screen and (min-width: 631px) {
.prod_item{
    margin: 15px;
    display: inline-block;
    background: whitesmoke;
    box-shadow: 5px 10px 20px 0 rgb(155, 155, 155);
    transition: 0.3s;
    overflow: hidden;
    max-width: 450px;
    max-height: 480px;
    border-radius: 5px;
}
.prod_item:hover{
        transition: transform .3s ease;
    transform: scale(1.05);
    cursor: pointer;
    text-decoration: none;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px; */
}
.prod_card{
    width: 450px;
    padding: 2px 16px;
    min-height: 230px;
    background-color: #404040;
}
.prod_header{
    font-size: 20px;
    color: whitesmoke;
    text-align: left;
    padding: 5px;
    padding-top: 20px;
    padding-bottom: 0px;
    font-weight: 400;
    width: 100%;
}
.prod_text{
    text-align: left;
    font-size: 15px;
    color: whitesmoke;
    padding: 5px;
    font-weight: 300;
    width: 100%;
}
.prod_learn{
    text-align: left;
    font-size: 15px;
    color: whitesmoke;
    padding: 5px;
    font-weight: 300;
    width: 100%;
}
.prod_learn:hover{
    text-decoration: underline;
}
.prod_img{
    max-width: 450px;
    max-height: 253px;
    border-radius: 5px 5px 0 0;
    /* max-width: 450px;
    min-width: 300px;
    height: min-content; */
}
  }

  @media only screen and (max-width: 630px) {
    .producttabcontentholder{
        padding-top: 2%;
        width: 100%;
        margin: auto;
      }
    .prod_item{
        margin: 15px;
        display: inline-block;
        background: whitesmoke;
        box-shadow: 5px 10px 20px 0 rgb(155, 155, 155);
        transition: 0.3s;
        overflow: hidden;
        max-width: 280px;
        max-height: 535px;
        border-radius: 5px;
    }
    .prod_item:hover{
            transition: transform .3s ease;
        transform: scale(1.05);
        cursor: pointer;
        /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
        width: 40%;
        border-radius: 5px; */
    }
    .prod_card{
        width: 280px;
        padding: 2px 16px;
        min-height: 280px;
        background-color:#404040;
    }
    .prod_header{
        font-size: 18px;
        color: whitesmoke;
        text-align: left;
        padding: 5px;
        padding-top: 15px;
        padding-bottom: 0px;
        font-weight: 400;
        width: 100%;
    }
    .prod_text{
        text-align: left;
        font-size: 15px;
        color: whitesmoke;
        padding: 5px;
        font-weight: 300;
        width: 100%;
    }
    .prod_learn{
        text-align: left;
        font-size: 15px;
        color: whitesmoke;
        padding: 5px;
        font-weight: 300;
        width: 100%;
    }
    .prod_learn:hover{
        text-decoration: underline;
    }
    .prod_img{
        max-width: 280px;
        max-height: 253px;
        border-radius: 5px 5px 0 0;
        /* max-width: 450px;
        min-width: 300px;
        height: min-content; */
    }
      }






/*------------------------ System products page----------------------------*/

  @media only screen and (min-width: 631px) {
.sys_prod_item{
    margin: 15px;
    display: inline-block;
    background: whitesmoke;
    box-shadow: 5px 10px 20px 0 rgb(155, 155, 155);
    transition: 0.3s;
    overflow: hidden;
    max-width: 300px;
    max-height: 500px;
    border-radius: 5px;
}
.sys_prod_item:hover{
        transition: transform .3s ease;
    transform: scale(1.05);
    cursor: pointer;
    text-decoration: none;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px; */
}
.sys_prod_card{
    width: 300px;
    padding: 2px 16px;
    min-height: 250px;
    background-color: #404040;
}
.sys_prod_header{
    font-size: 20px;
    color: whitesmoke;
    text-align: left;
    padding: 5px;
    padding-top: 20px;
    padding-bottom: 0px;
    font-weight: 400;
    width: 100%;
}
.sys_prod_text{
    text-align: left;
    font-size: 15px;
    color: whitesmoke;
    padding: 5px;
    font-weight: 300;
    width: 100%;
}
.sys_prod_learn{
    text-align: left;
    font-size: 15px;
    color: whitesmoke;
    padding: 5px;
    font-weight: 300;
    width: 100%;
}
.sys_prod_learn:hover{
    text-decoration: underline;
}
.sys_prod_img{
    max-width: 400px;
    max-height: 253px;
    border-radius: 5px 5px 0 0;
    /* max-width: 450px;
    min-width: 300px;
    height: min-content; */
}
  }

  @media only screen and (max-width: 630px) {
    .sys_producttabcontentholder{
        padding-top: 2%;
        width: 100%;
        margin: auto;
      }
    .sys_prod_item{
        margin: 15px;
        display: inline-block;
        background: whitesmoke;
        box-shadow: 5px 10px 20px 0 rgb(155, 155, 155);
        transition: 0.3s;
        overflow: hidden;
        max-width: 280px;
        max-height: 500px;
        border-radius: 5px;
    }
    .sys_prod_item:hover{
            transition: transform .3s ease;
        transform: scale(1.05);
        cursor: pointer;
        text-decoration:  none;
        /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
        width: 40%;
        border-radius: 5px; */
    }
    .sys_prod_card{
        width: 280px;
        padding: 2px 16px;
        min-height: 250px;
        background-color:#404040;
    }
    .sys_prod_header{
        font-size: 18px;
        color: whitesmoke;
        text-align: left;
        padding: 5px;
        padding-top: 15px;
        padding-bottom: 0px;
        font-weight: 400;
        width: 100%;
    }
    .sys_prod_text{
        text-align: left;
        font-size: 15px;
        color: whitesmoke;
        padding: 5px;
        font-weight: 300;
        width: 100%;
    }
    .sys_prod_learn{
        text-align: left;
        font-size: 15px;
        color: whitesmoke;
        padding: 5px;
        font-weight: 300;
        width: 100%;
    }
    .sys_prod_learn:hover{
        text-decoration: underline;
    }
    .sys_prod_img{
        max-width: 280px;
        max-height: 253px;
        border-radius: 5px 5px 0 0;
        /* max-width: 450px;
        min-width: 300px;
        height: min-content; */
    }
      }





      .prod_overlay_img{
        position: absolute;
        top : 25%;
        left: 55%;
      }

    @media only screen and (min-width: 1101px) {
       
      .underlay_img { 
        margin: auto;
        display: none; 
        width: 70%;
        left: 60rem;
        top: 18rem;
        box-shadow: 5px 10px 20px 0 rgb(155, 155, 155)
    } 
    }
    @media only screen and (max-width: 1100px) {

      .underlay_img { 
        margin: auto;
        display: block; 
        width: 70%;
        left: 60rem;
        top: 18rem;
        box-shadow: 5px 10px 20px 0 rgb(155, 155, 155)
    }
    }

    .software_details{
        display: flow-root;
        width:60%;
        margin:auto;

    }
    .software_details_text{
        width: 100%;
        margin: auto;
        padding-top: 2%;
        padding-bottom: 3%;
        text-align: left 
    }
    .software_text{
        text-align: justify;
        padding-top: 1%;
    }


@media only screen and (min-width: 2001px) {

    .software_img_body{
        padding-top: 3% !important;
        padding: 4%;
        display: block;
        float:right;
    }
    .vert-align-text{
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 400px;
    }
}
    @media only screen and (max-width: 2000px) {

    .software_img_body{
        padding-top: 3% !important;
        padding: 4%;
        display: block;
        float:right;
        width: 50%;
    }
    .vert-align-text{
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 400px;
    }
}
    @media only screen and (max-width: 1459px) {

    .software_img_body{
        padding-top: 3% !important;
        padding: 4%;
        display: block;
        margin: auto;
        width: 60%;
    }
    @media only screen and (max-width: 1459px) {
    .vert-align-text{
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 400px;
    }
}
@media only screen and (max-width: 1100px) {
    .vert-align-text{
        display: block;
        height: auto;
        width: 100%;
        margin: auto;
        padding-top: 2%;
        padding-bottom: 3%;
        text-align: left 
    }
}
}
    @media only screen and (max-width: 700px) {

    .software_img_body{
        padding-top: 3% !important;
        padding: 4%;
        display: block;
        margin: auto;
        width: 100%;
    }
    .vert-align-text{
        display: block;
        height: auto;
        width: 100%;
        margin: auto;
        padding-top: 2%;
        padding-bottom: 3%;
        text-align: left 
    }
}
      











@media only screen and (min-width: 1601px) {
.service_body{
    width: 80%;
    margin: auto;
    padding-top: 2%;
    padding-bottom: 3%;
    text-align: center;
}
.service_card{
    box-shadow: 5px 10px 20px 0 rgb(155, 155, 155);
    margin: 15px;
    display: inline-block;
    background: whitesmoke;
    transition: 0.3s;
    overflow: hidden;
    width: 40%;
    height: 300px;
    min-width: 320px;
    border-radius: 5px 5px 5px 5px;
}
.service_card:hover{
    transition: transform .3s ease;
    transform: scale(1.05);
    cursor: pointer;
    text-decoration:  none;
}

@media only screen and (min-width: 2301px) {
.service_card_img{
    padding: 1%;
    padding-top: 4%;
    padding-left: 10%;
    margin:0px auto !important;
    height: 250px;
    background: whitesmoke;
    transition: 0.3s;
    overflow: hidden;
    float:left;
    border-radius: 5px 0px 0px 5px;
}
}
@media only screen and (max-width: 2300px) {
    .service_card_img{
        padding: 1%;
        padding-top: 5%;
        padding-left: 8%;
        margin:0px auto !important;
        height: 200px;
        background: whitesmoke;
        transition: 0.3s;
        overflow: hidden;
        float:left;
        border-radius: 5px 0px 0px 5px;
    }
}

@media only screen and (max-width: 1700px) {
    .service_card_img{
        padding: 1%;
        padding-top: 5%;
        padding-left: 5%;
        margin:0px auto !important;
        height: 200px;
        background: whitesmoke;
        transition: 0.3s;
        overflow: hidden;
        float:left;
        border-radius: 5px 0px 0px 5px;
    }
}

.service_card_text{
    margin: 0px !important;
    padding: 3%;
    background: whitesmoke;
    transition: 0.3s;
    overflow: hidden;
    width: 60%;
    max-height: 500px;
    border-radius:  0px 5px 5px 0px ;
    text-align: left;
    float: right;
}
.service_card_header{
    margin: 0px !important;
    padding-bottom: 2%;
    background: whitesmoke;
    transition: 0.3s;
    overflow: hidden;
    width: 100%;
    max-height: 500px;
    border-radius:  0px 5px 5px 0px ;
    text-align: left;
    float: left;
}
}
@media only screen and (max-width: 1600px) {
    .service_body{
        width: 90%;
        margin: auto;
        padding-top: 2%;
        padding-bottom: 3%;
        text-align: center;
    }
    .service_card{
        box-shadow: 5px 10px 20px 0 rgb(155, 155, 155);
        margin: 15px;
        display: inline-block;
        background: whitesmoke;
        transition: 0.3s;
        overflow: hidden;
        width: 40%;
        height: 440px;
        min-width: 320px;
        border-radius: 5px 5px 5px 5px;
    }
    .service_card:hover{
        transition: transform .3s ease;
        transform: scale(1.05);
        cursor: pointer;
        text-decoration:  none;
    }
    
    .service_card_img{
        padding: 1%;
        display: inline-block;
        margin:0px auto !important;
        height: 200px;
        max-width: 200px;
        background: whitesmoke;
        transition: 0.3s;
        overflow: hidden;
        
        border-radius: 5px 0px 0px 5px;
    }
    
    
    .service_card_text{
        display: inline-block;
        margin: 0px !important;
        background: whitesmoke;
        transition: 0.3s;
        overflow: hidden;
        width: 100%;
        max-height: 500px;
        border-radius:  0px 5px 5px 0px ;
        text-align: center;
        padding: 2%;
    }
    .service_card_header{
        margin: 0px !important;
        background: whitesmoke;
        transition: 0.3s;
        overflow: hidden;
        width: 100%;
        max-height: 500px;
        border-radius:  0px 5px 5px 0px ;
        text-align: center;

    }
    }

@media only screen and (max-width: 1100px) {
    .service_body{
        width: 95%;
        margin: auto;
        padding-top: 2%;
        padding-bottom: 3%;
        text-align: center;
    }
    .service_card{
        box-shadow: 5px 10px 20px 0 rgb(155, 155, 155);
        margin: 15px;
        display: inline-block;
        background: whitesmoke;
        transition: 0.3s;
        overflow: hidden;
        width: 40%;
        height: 490px;
        min-width: 320px;
        border-radius: 5px 5px 5px 5px;
    }
    .service_card:hover{
        transition: transform .3s ease;
        transform: scale(1.05);
        cursor: pointer;
        text-decoration:  none;
    }
    
    .service_card_img{
        padding: 1%;
        display: inline-block;
        margin:0px auto !important;
        height: 200px;
        max-width: 200px;
        background: whitesmoke;
        transition: 0.3s;
        overflow: hidden;
        
        border-radius: 5px 0px 0px 5px;
    }
    
    
    .service_card_text{
        margin: 0px !important;
        background: whitesmoke;
        transition: 0.3s;
        overflow: hidden;
        width: 100%;
        max-height: 500px;
        border-radius:  0px 5px 5px 0px ;
        text-align: center;
        padding: 5%;
    }
    .service_card_header{
        margin: 0px !important;
        background: whitesmoke;
        transition: 0.3s;
        overflow: hidden;
        width: 100%;
        max-height: 500px;
        border-radius:  0px 5px 5px 0px ;
        text-align: center;

    }
    }

    .service_details_text_container{
        width: 80%;
        padding: 4%;
        margin: auto;
    }
    .service_details_text{
        padding-top:1% ;
    }